<template>
    <div>
        <Layout>
            <PageHeader :title="title" :items="items" />
            <form @submit.prevent="save">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 mx-auto">
                        <div class="add_coin_form_box mb-3">
                            <div class="row middle-row align-items-center">

                                <!-- SYMBOL -->
                                <div class="col-md-6 mb-3">
                                    <div class="form-group">
                                        <div class="w-50"><label for="text">Symbol:</label></div>
                                        <multiselect v-model.trim="$v.form.symbol.$model" track-by="id" label="symbol"
                                            :options="symbol_options" @input="onChangeSymbol(form.symbol)">
                                        </multiselect>
                                    </div>
                                    <div class="error mt-1" v-if="submitted && $v.form.symbol.$error">{{
                                            $v.form.symbol.$errors[0].$message
                                    }}</div>
                                </div>


                                <!-- NAME -->
                                <b-col md="12" class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                                    <b-form-group id="input-group-1" label="Name:" label-for="input-1">
                                        <b-form-input id="input-1" v-model.trim="$v.form.name.$model" type="text"
                                            placeholder="Name" readonly>
                                        </b-form-input>
                                    </b-form-group>
                                    <div class="error mt-1" v-if="submitted && $v.form.name.$error">{{
                                            $v.form.name.$errors[0].$message
                                    }}</div>
                                </b-col>

                                <!-- TABLE ROW START  -->
                                <div class="col-md-12">
                                    <div class="form-box">
                                        <div class="mb-2 d-flex align-items-center">
                                          <div class="w-50">
                                              <div class=" "><label for="text">Rounds:</label></div>
                                            <div v-if="strt!=''"> <p> (<span class="fw-bold">Note:</span> Rounds should fall between: {{strt}} and {{exp}})</p>
                                           
                                            </div>
                                          </div>
                                      
                                            <!-- ADD -->
                                            <div class=" w-50 text-end">
                                                <button title="Add new row" data-toggle="tooltip" data-placement="top"
                                                    type="button" id="btn" class="btn shadow-none border_box"
                                                    @click="addRow6()">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            fill="var(--navy-blue)" viewBox="0 0 448 512">
                                                            <path
                                                                d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                                                        </svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div v-for="(item, index) in rounds" :key="index"
                                            class="border_box d-flex align-items-center px-3  py-2 mb-3">
                                            <!-- CURRENCY -->
                                            <div class="w-100 me-3 ">
                                                <label for="text">Currency:</label>
                                                <div class="form-group">
                                                    <multiselect v-model="item.currency" track-by="keys" label="values"
                                                        :options="currency_options"></multiselect>
                                                    <div class="error"
                                                        v-if="submitted && $v.rounds.$each.$response.$errors">
                                                        <div v-for="(error, index) in $v.rounds.$each.$response.$errors[index].currency"
                                                            :key="index">
                                                            {{ error.$message }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- PRICE -->
                                            <div class="w-50 me-4">
                                                <label for="text">Price:</label>
                                                <div class="input-group form_liquidity">
                                                    <input class="form-control shadow-none" v-model="item.price"
                                                        onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                                                        onpaste="return false;" placeholder="" id="input5"
                                                        ref="cloneaddress" type="text"
                                                        aria-describedby="basic-addon3" />
                                                </div>
                                                <div class="error"
                                                    v-if="submitted && $v.rounds.$each.$response.$errors.length">
                                                    <div v-for="(error, index) in $v.rounds.$each.$response.$errors[index].price"
                                                        :key="index">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                             <!-- MIN AMOUNT -->
                                            <div class="w-50 me-4">
                                                <label for="text">Minimum Amount:</label>
                                                <div class="input-group form_liquidity">
                                                    <input class="form-control shadow-none" v-model="item.min_amount"
                                                        onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                                                        onpaste="return false;" placeholder="" id="input5"
                                                        ref="cloneaddress" type="text"
                                                        aria-describedby="basic-addon3" />
                                                </div>
                                                <div class="error"
                                                    v-if="submitted && $v.rounds.$each.$response.$errors.length">
                                                    <div v-for="(error, index) in $v.rounds.$each.$response.$errors[index].min_amount"
                                                        :key="index">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                              
                                            <div class="w-50 me-4">
                                                <label for="text">Maximum Amount:</label>
                                                <div class="input-group form_liquidity">
                                                    <input class="form-control shadow-none" v-model="item.max_amount"
                                                        onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                                                        onpaste="return false;" placeholder="" id="input5"
                                                        ref="cloneaddress" type="text"
                                                        aria-describedby="basic-addon3" />
                                                </div>
                                                <div class="error"
                                                    v-if="submitted && $v.rounds.$each.$response.$errors.length">
                                                    <div v-for="(error, index) in $v.rounds.$each.$response.$errors[index].max_amount"
                                                        :key="index">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>

                                              <!-- MAX AMOUNT -->
                                            
                                              <!-- total limits -->
                                            <div class="w-50 me-4">
                                                <label for="text">Total Limits:</label>
                                                <div class="input-group form_liquidity">
                                                    <input class="form-control shadow-none" v-model="item.total_limits"
                                                        onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                                                        onpaste="return false;" placeholder="" id="input5"
                                                        ref="cloneaddress" type="text"
                                                        aria-describedby="basic-addon3" />
                                                </div>
                                                <div class="error"
                                                    v-if="submitted && $v.rounds.$each.$response.$errors.length">
                                                    <div v-for="(error, index) in $v.rounds.$each.$response.$errors[index].total_limits"
                                                        :key="index">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>
                                              <!-- order_place_limit -->
                                            <!-- <div class="w-50 me-4">
                                                <label for="text">Order Place Limit:</label>
                                                <div class="input-group form_liquidity">
                                                    <input class="form-control shadow-none" v-model="item.order_place_limit"
                                                        onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                                                        onpaste="return false;" placeholder="" id="input5"
                                                        ref="cloneaddress" type="text"
                                                        aria-describedby="basic-addon3" />
                                                </div>
                                                <div class="error"
                                                    v-if="submitted && $v.rounds.$each.$response.$errors.length">
                                                    <div v-for="(error, index) in $v.rounds.$each.$response.$errors[index].order_place_limit"
                                                        :key="index">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- START AT -->
                                            <div class=" w-50 me-4">
                                                <label for="text">Start At:</label>
                                                <b-input-group class=" ">
                                                    <b-input-group-append>
                                                        <b-form-datepicker v-model="item.started_at"
                                                            button-only right today-button
                                                            reset-button locale="en-US" aria-controls="form-label"
                                                            @context="onContext"></b-form-datepicker>
                                                        <!-- <b-form-datepicker v-model="item.started_at"
                                                            :min="getMinCheck(item.started_at)" button-only right today-button
                                                            reset-button locale="en-US" aria-controls="form-label"
                                                            @context="onContext"></b-form-datepicker> -->
                                                    </b-input-group-append>
                                                    <b-form-input id="form-label"
                                                        onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                                                        onpaste="return false;" v-model="item.started_at" type="text"
                                                        placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>
                                                </b-input-group>
                                                <div class="error"
                                                    v-if="submitted && $v.rounds.$each.$response.$errors.length">
                                                    <div v-for="(error, index) in $v.rounds.$each.$response.$errors[index].started_at"
                                                        :key="index">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- EXPIRED AT -->
                                            <div class=" w-50 me-3">
                                                <label for="text">Expired At:</label>
                                                <b-input-group class=" me-3">
                                                    <b-input-group-append>
                                                        <b-form-datepicker v-model="item.expired_at"
                                                             button-only right today-button
                                                            reset-button locale="en-US" aria-controls="form-label"
                                                            @context="onContext"></b-form-datepicker>
                                                        <!-- <b-form-datepicker v-model="item.expired_at"
                                                            :max="getMinCheck(item.expired_at)" button-only right today-button
                                                            reset-button locale="en-US" aria-controls="form-label"
                                                            @context="onContext"></b-form-datepicker> -->
                                                    </b-input-group-append>
                                                    <b-form-input id="form-label"
                                                        onkeypress="return (event.charCode !=8 && ((event.charCode >= 48 && event.charCode <= 57) || (event.charCode == 46 && this.value.indexOf('.') == -1)))"
                                                        onpaste="return false;" v-model="item.expired_at" type="text"
                                                        placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>
                                                </b-input-group>
                                                <div class="error"
                                                    v-if="submitted && $v.rounds.$each.$response.$errors.length">
                                                    <div v-for="(error, index) in $v.rounds.$each.$response.$errors[index].expired_at"
                                                        :key="index">
                                                        {{ error.$message }}
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- DELETE  -->
                                            <div class="ms-2" v-if="index > 0 ? true : false">
                                                <button title="Delete" data-toggle="tooltip" data-placement="top"
                                                    type="button" id="btn" class="btn shadow-none border_box"
                                                    style="cursor: pointer;" @click="deleteRow6(index)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        fill="var(--navy-blue)" viewBox="0 0 320 512">
                                                        <path
                                                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- TABLE ROW END -->
                                <div class="col-12 mt-5">
                                    <div class="form-group btn-submit">
                                        <div class="spinner-border" role="status" v-if="loading">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <button type="submit" class="btn btn-primary" id="submit" v-if="!loading">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Layout>
    </div>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import { required } from "vuelidate/lib/validators";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Swal from "sweetalert2";
import ApiClass from "../../api/api";
import _ from "lodash";

export default {
    page: {
        title: "Edit Crypto",
        meta: [{
            name: "description",
            content: appConfig.description,
        },],
    },
    components: {
        Layout,
        PageHeader,
        Multiselect,
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            strt:"",
            exp:"",

            title: "Add Rounds",
            items: [{
                text: "Rounds",
                href: "#",
            },
            {
                text: "Add Rounds",
                active: true,
            },
            ],
            Arr: [],
            loading: false,
            value: '',
            formatted: '',
            selected: '',
            symbol_options: [],
            submitted: false,
            new_image: "",
            id: "",
            symbol_type: "",
            form: {
                symbol: "",
                name: "",
            },
            rounds: [{
                currency: null,
                price: null,
                started_at: null,
                expired_at: null,
                min_amount:null,
                max_amount:null,
                total_limits:null,
            // order_place_limit:null,
            },],
            currency_options: [
                { keys: "USDT", values: "USDT" },
                // { keys: "USDC", values: "USDC" },
            ],
        };
    },
    validations() {
        return {
            form: {
                name: { required },
                symbol: { required },
            },
            rounds: {
                $each: helpers.forEach({
                    currency: {
                        required: helpers.withMessage("Currency is required", required)
                    },
                    price: {
                        required: helpers.withMessage("Price is required", required)
                    },
                    started_at: {
                        required: helpers.withMessage("Date is required", required)
                    },
                    expired_at: {
                        required: helpers.withMessage("Date is required", required)
                    },
                    min_amount: {
                        required: helpers.withMessage("Minimum Amount is required", required)
                    },
                    max_amount: {
                        required: helpers.withMessage("Maximum Amount is required", required)
                    },
                    total_limits: {
                        required: helpers.withMessage("Total Limit is required", required)
                    }
                    // order_place_limit: {
                    //     required: helpers.withMessage("Order Place Limit is required", required)
                    // },
                })
            },
        }
    },
    mounted() {
        this.getTokenSymbol();
    },
    methods: {
        async getTokenSymbol() {
            var result = await ApiClass.getNodeRequest("launchpad/symbol_get", false);
            if (result?.data?.status_code == 1) {
                // console.log(result.data.data);
                this.symbol_options = result?.data?.data;
                // console.log('symbol code====',this.symbol_options)
            }
        },
        onChangeSymbol(symbol_type) {
            if (symbol_type != null) {
                this.strt = symbol_type.started_at
                this.exp =symbol_type.expired_at;

                this.form.name = symbol_type.name;
                this.rounds[0].started_at = symbol_type.started_at;
                this.rounds[this.rounds.length-1].expired_at = symbol_type.expired_at;
                this.id = symbol_type.id;
            }
        },
        async save() {
            this.submitted = true;
            const result1 = await this.v$.$validate();
            if (!result1) {
                return;
            }
            let d = [];
            this.rounds.forEach(function (value, index) {
                d.push(value)
                console.log("val", value, "index", index);
            });
            (this.rounds != "" && this.rounds != null) ? d : [];

            this.submitted = false;
            this.loading = true;
            let $this = this;

            let formData = {
                launch_token_id: this.id,
                currency: this.rounds[0].currency.keys,
                name: this.form.name,
                rounds: d.map((el) => {
                    return {
                        started_at: el.started_at,
                        expired_at: el.expired_at,
                        price: el.price,
                        min_amt: el.min_amount,
                        max_amt: el.max_amount,
                        total_limits: el.total_limits,
                        // order_place_limit: el.order_place_limit,
                        currency: el.currency.keys,
                        symbol: $this.form.symbol.symbol
                    }
                })
                // rounds : [{
                //     started_at:this.rounds[0].started_at,
                //     expired_at:this.rounds[0].expired_at,
                //     price:this.rounds[0].price,
                //     currency:this.rounds[0].currency.keys,
                //     symbol:this.form.symbol.symbol
                // }],

            }

            console.log({ formData });
            var result = await ApiClass.postNodeRequest("launchpad/create_rounds", true, formData);
            if (result.data.status_code == 1) {
                this.loading = false;
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: result.data.message,
                    showConfirmButton: true,
                    timer: 5000,
                });
            } else {
                this.loading = false;
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: result.data.message,
                    showConfirmButton: true,
                    timer: 5000,
                });
            }
        },
        onContext(ctx) {
            this.formatted = ctx.selectedFormatted
            this.selected = ctx.selectedYMD
        },
        addRow6() {
            this.rounds.push({
                currency: null,
                price: null,
                started_at: null,
                min_amount:null,
                max_amount:null,
                total_limits:null,
            // order_place_limit:null,
               expired_at: this.rounds[this.rounds.length-1]?.expired_at,
            });
            var $this =this;
            _.forEach(this.rounds, function (value, key) { 
                if(key!=$this.rounds.length - 1){ value.expired_at = null}
            });
   },
        deleteRow6(index) {
            if (index == 0) {
                return;
            }
            this.rounds[index-1].expired_at = this.rounds[index].expired_at;
            this.rounds.splice(index, 1);
        },

        getMinCheck(index) {
            let old_expiry = index;
            // add 1 day
            let nd = new Date(old_expiry);
            nd.setDate(nd.getDate());
            return nd;
        }
    },

};
</script>

<style scoped>
.add_coin_form_box {
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
    border-radius: 5px;
    padding: 15px 15px;
}

.form-group.btn-submit {
    text-align: center;
}

.form-group .currency-image {
    text-align: center;
    max-width: 44px;
    height: 44px;
    margin: 0 auto;
    border-radius: 26px;
    padding: 0;
    margin-top: 18px;
    margin-left: 0;
}

.form-group .currency-image img {
    max-width: 39px;
    margin-top: 5px;
}

.border_box {
    border: 1px solid #ebecf1;
    border-radius: 5px;
}
</style>
